export default {
  state: {
    token: null,
    isCollapse: false, //用于控制菜单的展开与折叠
    defaultAction: "/homes",
    tablist: [
      {
        path: "/homes",
        name: "homes",
        label: "首页",
        icon: "s-home",
        url: "/homes",
      },
    ], //面包屑数据
  },
  mutations: {
    // token
    click_token(state, val) {
      state.token = val;
    },
    click_defaultAction(state, val) {
      state.defaultAction = val.path;
    },
    // 修改菜单的展开折叠方法
    click_isCollapse(state) {
      // console.log('这是折叠',state.isCollapse)
      let show=false
      if(state.isCollapse){
        show=false
      }else{
        show=true
      }
      setTimeout(()=>{

        state.isCollapse = show;
      },500)
    },
    us_tabList(state, val) {
      state.tablist = [
        {
          path: "/homes",
          name: "homes",
          label: "首页",
          icon: "s-home",
          url: "/homes",
        },
      ];
    },
    // 更新面包屑数据
    selectMenu(state, value) {
      // console.log("这个是添加", value);

      // 判断添加的数据是不是首页
      if (value.path !== "/homes") {
        const index = state.tablist.findIndex(
          (item) => item.path === value.path
        );
        // 如果等于 -1 表名没有这个数据，然后只需要添加进去就好了
        if (index === -1) {
          state.tablist.push(value);
        }
        // console.log("这个是添加后", state.tablist);
        // console.clear();
      }
    },
    // 删除指定的面包屑数据
    close_tablist(state, value) {
      let index = state.tablist.findIndex((item) => item.path === value.path);
      state.tablist.splice(index, 1);
    },
  },
  actions: {},
};
